import React from "react";
import "../App.css";
import { Container } from "react-bootstrap";

export default function Navbar() {
  return (
    <div>
      <section  className="navbar">
        <Container>
          <div className="logo">
            <img className="img-fluid" src="/assets/logo.png" alt="logo" />
          </div>
        </Container>
      </section>
    </div>
  );
}
