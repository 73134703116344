import React from "react";
import { Container } from "react-bootstrap";
import Button from "@mui/material/Button";

export default function Section3() {
  return (
    <>
      <section className="section-container-1">
        <Container>
          <div className="d-flex section-2-combines">
            <div className="section-2">
              <div className="d-flex img-content">
                <div className="boxs">
                  <h4 className="txt-4">6 months free support</h4>
                  <p className="txt-5">
                    We don't just build your site and leave you hanging. Enjoy 6
                    months of FREE maintenance and support to ensure your
                    website continues to perform at its best
                  </p>

                  <h4 className="txt-4">Free Design Upgrade:</h4>
                  <p className="txt-5">
                    Stuck with an outdated look? Get a FREE redesign that's
                    modern, responsive, and optimized for conversions. Your new
                    site will not only look amazing but also function like a
                    dream.
                  </p>
                </div>
                <div className="combine-content">
                  <div className="content-1">
                    <h2 className="head-txt-2">
                      Transform Your Website, Transform Your Business!
                    </h2>
                  </div>

                  <div className="content-2">
                    <p className="txt-3">
                    <span className="txt-one">D</span>on't let an outdated, buggy website hold you back. Let us
                      upgrade you to a sleek, modern eCommerce platform that
                      drives sales and delights your customers.
                    </p>
                    <div>
                      <Button
                        variant="contained"
                        className="btn"
                        href="https://www.thetechhorse.com/contact-us"
                      >
                        Contact Us Now
                      </Button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="section-2-row">
                <div className="box-combine">
                  <div className="box">
                    <h4 className="txt-4">Zero Bugs Guaranteed</h4>
                    <p className="txt-5">
                      We'll transform your buggy website into a flawless,
                      bug-free platform that works seamlessly on all devices. No
                      more frustrated customers - just smooth shopping
                      experiences.
                    </p>
                    <div className="btn-5">
                      <Button
                        variant="contained"
                        className="btns"
                        href="https://www.thetechhorse.com/contact-us"
                      >
                        Contact Us Now
                      </Button>
                    </div>
                  </div>
                  <div className="box">
                    <h4 className="txt-4">Super Fast Performance</h4>
                    <p className="txt-5">
                      Slow load times are costing you sales. Upgrade to
                      blazing-fast speeds that keep your customers engaged and
                      shopping, not bouncing away.
                    </p>

                    <div className="btn-6">
                      <Button
                        variant="contained"
                        className="btns"
                        href="https://www.thetechhorse.com/contact-us"
                      >
                        Contact Us Now
                      </Button>
                    </div>
                  </div>
                  <div className="box">
                    <h4 className="txt-4">24/7 personalized Support</h4>
                    <p className="txt-5">
                      Your website is our priority. With round-the-clock
                      personalized support, you can rest easy knowing we're
                      always here to help
                    </p>

                    <div className="btn-7">
                      <Button
                        variant="contained"
                        className="btns"
                        href="https://www.thetechhorse.com/contact-us"
                      >
                        Contact Us Now
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="section-b">
              <h2 className="head-txt-3">
                The future of
                <span className="head-txt-3-1"> your business</span> is just one
                click away!
              </h2>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
}
