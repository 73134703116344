import React from "react";
import { Container } from "react-bootstrap";
import Button from "@mui/material/Button";
import Navbar from "../Navbar/Navbar";
import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Section4 from "./Section4";

export default function Home() {
  return (
    <>
      <Navbar />
      <section className="home-container">
        <Container>
          <div className="d-flex justify-content-between mobile-reverse">
            <div className="front-content">
              <div className="d-flex justify-content-end">
                <h3 className="head-txt">
                  "Transform your
                  <span className="head-txt-1"> online / offline </span>store
                  with our flawless
                  <span className="head-txt-1"> E-Commerce </span>design"
                </h3>
              </div>
              <div className="d-flex justify-content-center">
                <Button
                  variant="contained"
                  className="btn-contact"
                  href="https://www.thetechhorse.com/contact-us"
                >
                  Contact Us Now
                </Button>
              </div>
            </div>
            <div className="front-img">
              <img className="img-fluid" src="/assets/Group 21.png" alt="img" />
            </div>
          </div>
        </Container>
      </section>
      <Section2 />
      <Section3 />
      <Section1 />
      <Section4 />
    </>
  );
}
