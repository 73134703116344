import React from "react";
import { Container } from "react-bootstrap";
import Button from "@mui/material/Button";

export default function Section2() {
  return (
    <>
      <section className="section-container-1">
        <Container>
          <div className="d-flex section-2-combines">
            <div className="section-2">
            <div className="d-flex img-content">
            <div className="boxs">
                <h4 className="txt-4">6 months free support</h4>
                <p className="txt-5">
                  Enjoy 6 months of FREE maintenance and updates after your site
                  goes live. We've got your back as your business grows.
                </p>

                <h4 className="txt-4">Free Design:</h4>
                <p className="txt-5">
                  Pay nothing for design! Get a stunning, user-friendly
                  eCommerce website designed for FREE to captivate your
                  customers.
                </p>
              </div>
              <div className="combine-content">
                <div className="content-1">
                  <h2 className="head-txt-2">
                    Don't miss out on this unbeatable offer.
                  </h2>
                </div>

                <div className="content-2">
                  <p className="txt-3">
                    <span className="txt-one">C</span>ontact us NOW to kickstart your journey with
                    <span className="txt-2"> FREE</span> design and 6 months of
                    FREE service. Let's build your dream eCommerce website
                    today!
                  </p>
                  <div>
                    <Button
                      variant="contained"
                      className="btn"
                      href="https://www.thetechhorse.com/contact-us"
                    >
                      Contact Us Now
                    </Button>
                  </div>
                </div>
              </div>
            </div>

              <div className="section-2-row">
                <div className="box-combine">
                  <div className="box">
                    <h4 className="txt-4">Zero bugs</h4>
                    <p className="txt-5">
                      We deliver flawless websites with zero bugs guaranteed. No
                      more stress over technical issues.
                    </p>
                    <div className="btn-2">
                      <Button
                        variant="contained"
                        className="btns"
                        href="https://www.thetechhorse.com/contact-us"
                      >
                        Contact Us Now
                      </Button>
                    </div>
                  </div>
                  <div className="box">
                    <h4 className="txt-4">lightning-fast delivery</h4>
                    <p className="txt-5">
                      Time is money. We build your website quickly so you can
                      start making sales ASAP.
                    </p>

                    <div className="btn-2">
                      <Button
                        variant="contained"
                        className="btns"
                        href="https://www.thetechhorse.com/contact-us"
                      >
                        Contact Us Now
                      </Button>
                    </div>
                  </div>
                  <div className="box">
                    <h4 className="txt-4">24/7 personalized care</h4>
                    <p className="txt-5">
                      We're always here for you! Get round-the-clock
                      personalized support, ensuring your website runs smoothly
                      and your questions are answered.
                    </p>

                    <div className="btn-3">
                      <Button
                        variant="contained"
                        className="btns"
                        href="https://www.thetechhorse.com/contact-us"
                      >
                        Contact Us Now
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="section-b">
              <h2 className="head-txt-3">
                Your <span className="head-txt-3-1">E-Commerce</span> success
                story starts here!
              </h2>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
}
