import React, { useState } from "react";
import Button from "@mui/material/Button";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Flex, Typography } from "antd";
import { Input } from "antd";
import axios from "axios";

export default function Section4() {
  const [data, setData] = useState({
    name: "",
    phone: "",
    mail: "",
    subject: "",
  });

  const [loading, setLoading] = useState(false); 
  const handleBooking = async (e: any) => {
    e.preventDefault();
    if (!data.name || !data.phone || !data.mail || !data.subject) {
      alert("Please fill out all fields");
      return;
    }
    setLoading(true); 
    try {
      await axios.post("https://tth-website-api.onrender.com/ContactUs", {
        name: data.name,
        userPhonenumber: data.phone,
        userEmail: data.mail,
        subject: data.subject,
      });
      setData({
        name: "",
        mail: "",
        phone: "",
        subject: "",
      });

    } catch (err: any) {
      console.error(err.message);
      alert("Failed to submit. Please try again.");
    } finally {
      setLoading(false); 
    }
  };

  return (
    <>
      <section className="section">
        <Container>
          <div>
            <Row>
              <Col xs={12} lg={6} md={6}>
                <h2 className="f-txt-1">
                  Let us bring your <span className="f-txt-2">eCommerce</span>{" "}
                  dream to life with our premium website design and development
                  services that{" "}
                  <span className="f-txt-2">guarantee success </span>
                  from day one!
                </h2>
              </Col>
              <Col xs={12} lg={6} md={6}>
                <div className="d-flex gap-1 mt-2">
                  <div>
                    <h2 className="f-txt-3">Fill the Form</h2>
                  </div>
                  <div>
                    <img
                      src="assets/Group 34.png"
                      alt="img"
                      className="img-fluid img-arrow"
                    />
                  </div>
                </div>
                <div>
                  <Flex vertical gap={16}>
                    <div>
                      <Typography.Title level={5} className="labels">
                        Name
                      </Typography.Title>
                      <Input
                        placeholder="Enter your name"
                        id="name"
                        value={data.name}
                        onChange={(e) =>
                          setData({ ...data, name: e.target.value })
                        }
                      />
                    </div>
                    <div>
                      <Typography.Title level={5} className="labels">
                        Phone Number
                      </Typography.Title>
                      <Input
                        value={data.phone}
                        onChange={(e) =>
                          setData({ ...data, phone: e.target.value })
                        }
                      />
                    </div>
                    <div>
                      <Typography.Title level={5} className="labels">
                        Email Id
                      </Typography.Title>
                      <Input
                        placeholder="Enter your email"
                        value={data.mail}
                        onChange={(e) =>
                          setData({ ...data, mail: e.target.value })
                        }
                      />
                    </div>
                    <div>
                      <Typography.Title level={5} className="labels">
                        Subject
                      </Typography.Title>
                      <Form.Control
                        as="textarea"
                        style={{ height: "150px" }}
                        value={data.subject}
                        onChange={(e) =>
                          setData({ ...data, subject: e.target.value })
                        }
                      />
                    </div>
                    <div className="d-flex justify-content-center align-items-center">
                      <Button
                        variant="contained"
                        className="btn-contact-3"
                        onClick={handleBooking} 
                        disabled={loading} 
                      >
                        {loading ? "Submitting..." : "Book A Call"}
                      </Button>
                    </div>
                  </Flex>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
}
