import React from "react";
import { Container } from "react-bootstrap";

export default function Section1() {
  return (
    <>
      <section className="section-container">
        <Container>
          <div className="section-1">
            <div>
              <img src="\assets\Group 31.png" className="img-fluid" alt="img" />
            </div>
            <div className="d-lg-flex section-combine">
              <div className="d-flex align-items-end">
                <div className="box-1">
                  <h2>OUR</h2>
                  <h2>ADVANTAGES</h2>
                </div>
              </div>
              <div className="d-lg-flex about-combine">
                <div className="box-2">
                  <h6>Team Of Experts</h6>
                  <p>
                    Our team is composed of seasoned professionals who bring a
                    wealth of experience and knowledge to every project. With
                    diverse backgrounds in design, development, and strategy, we
                    collaborate closely to deliver innovative solutions tailored
                    to your unique needs.
                  </p>
                </div>
                <div className="box-2">
                  <h6>Personalization</h6>
                  <p>
                    we ensure that every aspect of our service aligns with your
                    specific goals and preferences. By taking the time to
                    understand your needs and aspirations, we create experiences
                    that resonate deeply with your audience and reflect your
                    brand's distinct personality. Your success is our priority,
                    and personalization is how we make it happen.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
}
